<template>
  <div id="order-confirmed">
    <v-container>
      <v-row class="top-bar">
        <v-col cols="10"></v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            icon
            class="menu-btn"
            @click="showNavDrawer = true"
          >
            <v-icon size="18">
              {{menu_icon}}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div class="my-page-content">
        <v-row class="mt-5">
          <v-col cols="6" offset="6" class="pa-0 logo-icon-container">
            <div>
              <v-img
                width="125"
                height="125"
                src="@/assets/wecook-logo-big.svg"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div class="title-0 order-time-background">
              {{time_slot}}
            </div>
          </v-col>
          <v-col cols="6" class="pa-0 frame-blue-container">
            <div>
              <v-img
                width="148"
                height="153"
                src="@/assets/frame-blue.svg"
              ></v-img>
            </div>
          </v-col>
        </v-row>

        <v-row class="text-center mt-5">
          <v-col cols="12">
            <div class="title-5">
              {{messages[orderReceiveMethod].title}}
            </div>
          </v-col>
          <v-col cols="10" offset="1">
            <div class="title-9">
              {{messages[orderReceiveMethod].text}}
            </div>
          </v-col>
        </v-row>

        <v-row class="text-center mt-5">
          <v-col cols="12">
            <v-btn
              text
              class="title-12"
              color="#001254"
              @click="goToSupportPage"
            >
              Για οποιαδήποτε βοήθεια επικοινώνησε μαζί μας!
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2" offset="3" class="text-center">
            <v-btn icon href="https://wa.me/306972992817">
              <v-img
                src="@/assets/whatsapp_icon.png"
                width="50"
                height="50"
              ></v-img>
            </v-btn>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn icon href="https://l1nq.link/?viber=306972992817">
              <v-img
                src="@/assets/viber_icon.png"
                width="50"
                height="50"
              ></v-img>
            </v-btn>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-menu
              top
              :offset-y="true"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-img
                    src="@/assets/phone_icon.png"
                    width="50"
                    height="50"
                  ></v-img>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>2106420888</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col cols="12" class="text-center">
            <v-btn
              depressed
              filled
              color="#001254"
              height="52"
              block
              class="see-order-btn"
              @click="goToOrderDetails"
            >
              See my order
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <NavigationDrawer
        :visible="showNavDrawer"
        @close="showNavDrawer = false"
      />
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';

  export default {
    name: 'OrderConfirmed',
    components: {
      NavigationDrawer
    },
    computed: {
      ...mapGetters([
        "orderDetails",
        "orderReceiveMethod",
        "minutesUntilDelivery",
        "minutesUntilDeliveryText",
        "minutesUntilTakeAway",
        "minutesUntilTakeAwayText"
      ]),
      messages() {
        let result = {
          'delivery': {
            time: this.minutesUntilDelivery + "'",
            title: 'Order on it\'s way',
            text: this.minutesUntilDeliveryText
          },
          'pickup': {
            time: this.minutesUntilTakeAway + "'",
            title: 'Pick up time!',
            text: this.minutesUntilTakeAwayText
          }
        };
        return result;
      },
      time_slot() {
        let result = '';
        if (Object.keys(this.orderDetails).length !=0 ) {
          result = this.orderDetails.customHour;
        }
        return result;
      }
    },
    data() {
      return {
        showNavDrawer: false,
        menu_icon: "$vuetify.icons.menu_icon"
      };
    },
    methods: {
      ...mapActions([
        "setOrderDetails"
      ]),
      goToOrderDetails() {
        this.$router.push({name: 'order_details'});
      },
      goToSupportPage() {
        this.$router.push({name: 'support'});
      }
    }
  };
</script>

<style type="text/css" scoped>
  #order-confirmed {
    background: #5BE789;
    height: 100%;
  }

  .order-time-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    background-image: url('~@/assets/frame-white-background.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .logo-icon-container {
    margin-bottom: -30px;
  }

  .frame-blue-container {
    display: flex;
    justify-content: flex-end;
    margin-top: -70px;
  }

  .see-order-btn {
    padding: 17px 30px;
    border-radius: 44px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
  }
</style>

<style type="text/css">
  #order-confirmed .top-bar {
    background: transparent;
  }
</style>